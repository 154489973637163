/* App.css */

/* Importações de fontes e ícones */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif; /* Aplicar a fonte Montserrat */
    background-color: white;
    scroll-behavior: smooth; /* Adiciona rolagem suave */
}

.app-container {
    text-align: center;
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    height: 60px;
    margin-right: 20px;
}


.mobile-nav {
    display: none;
    position: absolute;
    top: 80px;
    right: 0;
    width: 250px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 1000;
    border: 1px solid #000; /* Adiciona uma borda fina e preta */
}

.hero {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 700px;
    background-image: url('./images/Pag_inicial.jpeg'); /* Nova imagem */
    background-size: cover;
    background-position: center;
    text-align: center;
}

.hero-content {
    position: relative;
    z-index: 2;
    text-align: center;
    color: #fff;
    padding: 20px;
    background: rgba(0, 191, 255, 0.7); /* Fundo azulado semi-transparente #00BFFF */
    border-radius: 50px;
    font-family: 'Montserrat', sans-serif; /* Aplicar a fonte Montserrat */
    margin-top: -200px; /* Mover o container para cima */
}

.ai-text {
    font-size: 2.5em;
    font-weight: bold;
    color: #007bff; /* Cor para Inteligência Artificial (IA) */
    margin: 0.5em 0;
}

.security-text {
    font-size: 2.5em;
    font-weight: bold;
    color: #fff; /* Cor branca para Segurança Eletrônica */
    margin: 0.5em 0;
}

.security-text span {
    font-weight: normal; /* Remover negrito do "e" */
    color: #fff; /* Manter a cor branca para o "e" */
}

.scroll-button {
    background-color: #007bff;
    color: white;
    padding: 25px 100px; /* Aumentar o tamanho do botão */
    font-size: 30px;
    font-weight: bold;    
    border: none;
    border-radius: 100px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none;
    position: absolute;
    bottom: 20px;
    left: 20px; /* Posicionar no canto inferior esquerdo */
}

.scroll-button:hover {
    background-color: #0056b3;
}

/* Estilos para a seção Contato */
.contact-section {
    padding: 50px;
    background-color: #f9f9f9;
    text-align: center;
}

.contact-section h2 {
    font-size: 3em;
    color: #007bff;
    margin-bottom: 20px;
}

.contact-section p {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 40px;
}

.contact-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none;
    margin-top: 20px;
}

.contact-button:hover {
    background-color: #0056b3;
}

/* Estilos para o rodapé */
footer {
    background-color: #333;
    color: white;
    padding: 20px 0;
    text-align: center;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
}

.footer-content p {
    margin: 0;
    padding: 0;
}

.footer-content .social-icons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.footer-content .social-icons a {
    color: white;
    margin: 0 20px;
    font-size: 2em;
    text-decoration: none;
}

.footer-content .social-icons a:hover {
    color: #007bff;
}

/* Estilo para a imagem no footer */
.footer-logo {
    width: 40px; /* Ajuste o tamanho conforme necessário */
    height: auto;
    margin: 0 5px; /* Espaçamento entre ícones */
    border-radius: 5px; /* Adiciona bordas arredondadas, se necessário */
    transition: transform 0.3s;
}

.footer-logo:hover {
    transform: scale(1.1); /* Efeito de zoom ao passar o mouse */
}

/* Media queries para dispositivos móveis */
@media (max-width: 768px) {
    .hamburger-menu {
        display: block;
    }

    nav {
        display: none;
    }

    .mobile-nav {
        display: none;
    }
}

/* Media queries para dispositivos menores que 480px */
@media (max-width: 480px) {
    header {
        padding: 10px;
    }

    .logo img {
        height: 60px;
        margin-right: 5px;
        margin-left: 5px;
    }

    .login-button {
        padding: 10px 20px;
        font-size: 14px;
        border-radius: 10px;
    }

    .hero {
        height: 300px;
    }

    .hero-content {
        font-size: 1em;
        padding: 5px;
        margin-top: 0;
    }

    .ai-text, .security-text {
        font-size: 1.5em;
    }

    .scroll-button {
        padding: 10px 30px;
        font-size: 16px;
        bottom: 5px;
        left: 5px;
    }

    .contact-section h2 {
        font-size: 1.5em;
    }

    .contact-section p {
        font-size: 0.9em;
    }

    .contact-button {
        padding: 8px 10px;
        font-size: 12px;
    }

    footer {
        padding: 10px 0;
    }

    footer .social-icons a {
        font-size: 1.2em;
        margin: 0 5px;
    }
}
