/* Estilo geral da página de ocorrências */
.ocorrencias-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
    padding: 20px;
}

.ocorrencias-page h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 42px;
    margin-bottom: 20px;
    color: #007bff
}

/* Estilo da imagem de ocorrência */
.ocorrencias-page img {
    max-width: 90%;
    height: 500px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    max-height: 500px;
    object-fit: contain;
}

/* Estilo dos botões de feedback */
.ocorrencias-page button {
    background-color: #0056b3;
    color: white;
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    margin: 10px;
    width: 48%; /* Ajusta a largura para ficar ao lado do outro */
}

.ocorrencias-page button:hover {
    background-color: #004494;
}

.feedback-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
}

/* Responsividade para dispositivos móveis */
@media (max-width: 480px) {
    .ocorrencias-page h1 {
        font-size: 22px;
    }

    .ocorrencias-page img {
        width: 100%;
        height: auto;
        max-height: 300px;
    }

    .feedback-container {
        flex-direction: column;
    }

    .ocorrencias-page button {
        font-size: 16px;
        width: 100%;
        margin: 10px 0;
    }
}

/* Responsividade para tablets */
@media (max-width: 768px) {
    .ocorrencias-page h1 {
        font-size: 24px;
    }

    .ocorrencias-page img {
        width: 100%;
        height: auto;
        max-height: 400px;
    }

    .feedback-container {
        max-width: 400px;
    }

    .ocorrencias-page button {
        font-size: 18px;
    }
}
