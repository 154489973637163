.register-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1000px;
    width: 100%;
    background-image: url('../images/drone_login.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 50px;
    padding-top: 50px; /* Evita colisão com o Header */
}

.register-container {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
    margin-bottom: 200px;
    position: relative; /* Necessário para posicionar o botão de voltar */
}

.register-container h2 {
    margin-bottom: 20px;
    color: #333;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    position: relative;
}

.back-button {
    background: none;
    border: none;
    color: #333;
    font-size: 20px; /* Ajustado para o tamanho do ícone */
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    cursor: pointer;
    position: absolute; /* Posiciona no canto superior esquerdo */
    top: 20px;
    left: 20px;
}

.back-button i {
    font-size: 16px; /* Ajuste do tamanho do ícone */
}

.back-button:hover {
    color: #D8A43E; /* Cor do hover */
}


.input-group {
    margin-bottom: 15px;
    text-align: left;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
    color: #555;
    font-family: 'Montserrat', sans-serif;
}

.input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.success-message {
    color: green;
    font-size: 14px;
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
}

.register-button {
    background-color: #D8A43E;
    color: white;
    padding: 10px 20px;
    font-size: 22px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}

.register-button:hover {
    background-color: #cc7a00;
}

.signup-link {
    margin-top: 20px;
    font-size: 14px;
    color: #555;
    font-family: 'Montserrat', sans-serif;
}

.signup-link a {
    color: #D8A43E;
    text-decoration: none;
}

.signup-link a:hover {
    text-decoration: underline;
}

.error-message {
    color: red;
    font-size: 0.875em;
}

/* Estilo para as dicas */
.tips-toggle {
    margin-top: 15px;
    font-size: 12px;
    color: #D8A43E; /* Cor do botão de registro */
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
}

.tips-content {
    margin-top: 10px;
    font-size: 10px;
    color: #555;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
}

/* Media Queries */
@media (max-width: 480px) {
    .register-container {
        padding: 20px;
        width: 90%;
        margin-top: 20px; /* Ajuste adicional para dispositivos móveis */
    }

    .register-button {
        font-size: 18px;
    }

    .tips-toggle {
        font-size: 10px;
    }

    .tips-content {
        font-size: 8px;
    }
}

@media (max-width: 768px) {
    .register-container {
        width: 80%;
        padding: 30px;
    }

    .register-button {
        font-size: 20px;
    }
}
