/* Welcome.css */

.welcome-page {
    text-align: center;
    margin-top: 50px;
}

.welcome-page h1 {
    font-size: 2.5em;
    color: #007bff;
}

.welcome-page p {
    font-size: 1.2em;
    color: #333;
}
