/* Código original mantido */
/* Adicionado para dispositivos maiores que 1200px */
.who-we-are {
    position: relative;
    background-image: url('./images/drone_final.jpg');
    background-size: cover;
    background-position: center;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
}

@media (min-width: 1201px) {
    .who-we-are {
        height: 800px; /* Ajuste para telas maiores */
    }

    .who-we-are-content h3 {
        font-size: 3.5em;
    }

    .who-we-are-content p {
        font-size: 1.5em;
    }
}

/* Estilos para tablets (dispositivos com largura máxima de 1024px) */
@media (max-width: 1024px) {
    .who-we-are {
        height: 600px;
    }

    .who-we-are-content {
        max-width: 80%;
        padding: 15px;
    }

    .who-we-are-content h3 {
        font-size: 2.5em;
    }

    .who-we-are-content p {
        font-size: 1.2em;
    }
}

/* Código original mantido */
/* Responsividade existente para dispositivos com largura máxima de 768px */
@media (max-width: 768px) {
    .who-we-are {
        height: 400px;
    }

    .who-we-are-content {
        max-width: 90%;
        padding: 10px;
    }

    .who-we-are-content h3 {
        font-size: 2em;
    }

    .who-we-are-content p {
        font-size: 1em;
    }
}

/* Responsividade existente para dispositivos com largura máxima de 480px */
@media (max-width: 480px) {
    .who-we-are {
        height: 300px;
    }

    .who-we-are-content h3 {
        font-size: 1.5em;
    }

    .who-we-are-content p {
        font-size: 0.9em;
    }
}

/* Código original mantido */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.who-we-are-content {
    position: relative;
    z-index: 2;
    max-width: 800px;
    padding: 20px;
}

.who-we-are-content h3 {
    font-size: 3em;
    color: #D8A43E;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}

.who-we-are-content p {
    font-size: 1.2em;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}
