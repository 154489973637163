/* Login.css */

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1000px;
    width: 100%;
    background-image: url('../images/drone_login.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 50px;
    padding-top: 50px; /* Evita colisão com o Header */
}

.login-container {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
    margin-bottom: 200px;
}

.login-container h2 {
    margin-bottom: 20px;
    color: #333;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

.input-group {
    margin-bottom: 15px;
    text-align: left;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
    color: #555;
    font-family: 'Montserrat', sans-serif;
}

.input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.success-message {
    color: green;
    font-size: 14px;
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
}

.login-button {
    background-color: #D8A43E;
    color: white;
    padding: 10px 20px;
    font-size: 22px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}

.login-button:hover {
    background-color: #cc7a00;
}

.signup-link {
    margin-top: 20px;
    font-size: 14px;
    color: #555;
    font-family: 'Montserrat', sans-serif;
}

.signup-link a {
    color: #D8A43E;
    text-decoration: none;
}

.signup-link a:hover {
    text-decoration: underline;
}

.forgot-password a {
    color: #D8A43E; /* Cor amarela */
    text-decoration: underline;
    font-family: 'Montserrat', sans-serif;
    margin-top: 6px;
    display: block; 
    text-align: center;/* Para garantir que fique em uma linha separada */
}

.forgot-password a:hover {
    text-decoration: none;
}

/* CSS para alinhar o ícone ao lado do input de senha */
.password-input {
    position: relative;
}

.password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px;
    margin-top: 12px;
}


/* Media Queries */
@media (max-width: 480px) {
    .login-container {
        padding: 20px;
        width: 90%;
        margin-top: 20px; /* Ajuste adicional para dispositivos móveis */
    }

    .login-button {
        font-size: 18px;
    }
}

@media (max-width: 768px) {
    .login-container {
        width: 80%;
        padding: 30px;
    }

    .login-button {
        font-size: 20px;
    }
}
