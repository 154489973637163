@media (max-width: 768px) {
    .drones {
        height: 400px;
        padding: 20px;
    }

    .drones-content {
        max-width: 90%;
        text-align: center;
    }

    .drones-content h2 {
        font-size: 2em;
        text-align: center;
    }

    .drones-text {
        max-width: 100%;
        text-align: center;
        margin-top: 20px;
        float: none;
    }

    .drones-text p {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .drones {
        height: 300px;
        padding: 15px;
    }

    .drones-content h2 {
        font-size: 1.5em;
    }

    .drones-text p {
        font-size: 0.9em;
    }
}

.drones {
    position: relative;
    background-image: url('./images/drone_voando_futuro.jpg');
    background-size: cover;
    background-position: center;
    height: 900px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    padding: 50px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.drones-content {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    width: 100%;
}

.drones-content h2 {
    font-size: 4em;
    color: #D8A43E;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 20px;
    text-align: right;
    margin-top: 0;
}

.drones-text {
    max-width: 50%;
    text-align: right;
    margin-top: 50px;
    float: right;
}

.drones-text p {
    font-size: 1.5em;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.6;
}

.drones-text strong {
    color: #007bff;
}
