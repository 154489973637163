/* Header.css */

/* Importações de fontes e ícones */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

/* Estilo básico do Header */
header {
    background-color: #fff;
    padding: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #ddd;
    position: relative;
}

/* Container para logo e bandeiras */
.logo-bandeira-container {
    display: flex;
    align-items: center;
}

/* Estilo da logo */
.logo {
    display: flex;
    align-items: center;
}

.logo img {
    height: 60px;
    margin-right: 20px;
}

/* Estilo das bandeiras ao lado da logo */
.language-selector {
    display: flex;
    align-items: center;
}

.language-flag {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-left: 10px;
}

/* Menu de navegação à direita */
nav {
    display: flex;
    margin-left: auto; /* Mantém o menu à direita */
}

nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

nav ul li {
    margin: 0 20px;
}

nav ul li a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}

nav ul li a:hover {
    color: #007bff;
}

.login-button {
    background-color: #007bff;
    color: white; /* Letras em branco */
    padding: 10px 20px;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    transition: background-color 0.3s;
    border: none;
    cursor: pointer;
}

.login-button:hover {
    background-color: #0056b3;
}

.logout-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 22px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}

.logout-button:hover {
    background-color: #0056b3;
}

.hamburger-menu {
    display: none;
    font-size: 3em;
    cursor: pointer;
}

.mobile-nav {
    display: none;
    position: absolute;
    top: 80px;
    right: 0;
    width: 300px; 
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 1000;
    border: 1px solid #000;
    padding: 20px;
}

.mobile-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-nav ul li {
    margin: 15px 0;
}

.mobile-nav ul li a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2em;
}

.mobile-nav ul li a:hover {
    color: #007bff;
}

.mobile-nav .login-button {
    color: white; /* Garante que as letras fiquem brancas no menu mobile */
}

.mobile-nav.open {
    display: block;
}

/* Estilo para os itens do menu no modo desktop */
.desktop-nav ul li a {
    font-size: 1.2em;
}

/* Adicionando uma nova classe para quando estiver logado */
.logged-in-nav ul li a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2em;
    align-items: center;
    margin-top: 10px;
}

.logged-in-nav ul li a:hover {
    color: #007bff;
}

.logged-in-nav .login-button, .logged-in-nav .logout-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    transition: background-color 0.3s;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
}

.logged-in-nav .login-button:hover, .logged-in-nav .logout-button:hover {
    background-color: #0056b3;
}

/* Media queries */
@media (max-width: 768px) {
    .desktop-nav {
        display: none;
    }

    .hamburger-menu {
        display: block;
    }
}
