@media (max-width: 768px) {
    .robots {
        height: 400px;
        padding: 20px;
    }

    .robots-content {
        max-width: 90%;
        text-align: center;
    }

    .robots-content h2 {
        font-size: 2em;
        text-align: center;
    }

    .robots-text {
        max-width: 100%;
        text-align: center;
        margin-top: 20px;
    }

    .robots-text p {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .robots {
        height: 300px;
        padding: 15px;
    }

    .robots-content h2 {
        font-size: 1.5em;
    }

    .robots-text p {
        font-size: 0.9em;
    }
}

.robots {
    position: relative;
    background-image: url('./images/Cachorro_robo_futuro.webp');
    background-size: cover;
    background-position: center;
    height: 900px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    padding: 50px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.robots-content {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    width: 100%;
}

.robots-content h2 {
    font-size: 3em;
    color: #007bff;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 20px;
    text-align: left;
    margin-top: 0;
}

.robots-text {
    max-width: 50%;
    text-align: left;
    margin-top: 50px;
}

.robots-text p {
    font-size: 1.5em;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.6;
}

.robots-text strong {
    color: #007bff;
}
